<template>
  <page-layout>
    <v-col cols="12" class="accruals-table">
      <simple-card-with-tabs :items="tabItems" :need-subtitle="true">
        <template v-slot:title-left>Начисления, платежи, ЕПД</template>
        <template v-slot:title-right>
          <v-btn
            color="primary"
            @click="goToPaymentPage"
            :block="isMobile"
            :class="'full-width ' + (!isMobile ? 'mr-2' : '')"
          >
            Оплатить
          </v-btn>
          <v-btn
            color="secondary"
            @click="accrualsService.downloadReceipt"
            :loading="needLoadingIndicator"
            :block="isMobile"
            class="full-width"
          >
            Квитанция <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <template v-slot:subtitle v-if="!activeBinding.outer">
          <v-switch
            v-model="sendEpd"
            class="mt-2 pt-0"
            @change="sendEpdChange"
            color="primary"
            hide-details
          >
            <template v-slot:label>
              <span class="">{{ sendEpdText }}</span>
            </template>
          </v-switch>
          <v-expand-transition>
            <v-container v-show="sendEpdShow" class="mt-3">
              <v-row :class="isMobile || 'email-input'">
                <v-text-field
                  v-model="emailEpd"
                  :rules="[rules.required, rules.validEmail]"
                  outlined
                  dense
                ></v-text-field>
              </v-row>
              <v-row class="mt-2">
                <v-btn
                  :disabled="invalidEmail || sendEpdLoading"
                  :block="isMobile"
                  color="primary"
                  class="full-width mt-0"
                  @click="sendEpdConfirm"
                  :loading="sendEpdLoading"
                >
                  Сохранить
                </v-btn>
              </v-row>
            </v-container>
          </v-expand-transition>
        </template>
      </simple-card-with-tabs>
    </v-col>
  </page-layout>
</template>

<script>
import SimpleCardWithTabs from "../../components/cards/SimpleCardWithTabs";
import PageLayout from "../../components/Layouts/PageLayout";
import {mapGetters, mapState} from "vuex";
import AccrualsService from "../../services/AccrualsService";
import loadingIndicator from "../../shared/mixins/loadingIndicator";

export default {
  name: "Accruals",
  components: { SimpleCardWithTabs, PageLayout },
  mixins: [loadingIndicator],
  data() {
    return {
      rules: {
        required: v => !!v || "Необходимо заполнить это поле",
        validEmail: v => /.+@.+\..+/.test(v) || !v || "Некорректный адрес электронной почты",
      },
      sendEpdInit: false,
      sendEpd: false,
      emailEpd: "",
      sendEpdLoading: false,
      loadingFlag: "get_epd",
      accrualsService: AccrualsService,
    };
  },
  computed: {
    ...mapState("user", ["login", "activeBinding"]),
    ...mapGetters("account", ["lastChargesByService", "chargeItems"]),
    ...mapState("app", {
      isMobile: (state) => state.isMobile,
    }),
    detailsRows() {
      return this.accrualsService.getDetailsRows(this.lastChargesByService);
    },
    historyRows() {
      return this.accrualsService.getHistoryRows(this.chargeItems);
    },
    tabItems() {
      return this.accrualsService.getTabItems(
        this.detailsRows,
        this.historyRows
      );
    },
    invalidEmail() {
      return !(/.+@.+\..+/.test(this.emailEpd)); // || !!this.emailEpd;
    },
    sendEpdText() {
      return "Получать квитанцию по e-mail" + (this.sendEpdInit ? " (" + this.emailEpd + ")" : "");
    },
    sendEpdShow() {
      if (this.sendEpdInit) {
        return false;
      }
      return this.sendEpd;
    },
  },
  mounted() {
    this.onPAgeShown();
  },
  updated() {
    this.onPAgeShown();
  },
  methods: {
    onPAgeShown() {
      this.sendEpdInit = this.activeBinding.send_epd === 1;
      this.sendEpd = this.activeBinding.send_epd === 1;
      this.emailEpd = this.activeBinding.email_epd;
    },
    goToPaymentPage() {
      this.$router.push({ name: "payment" });
    },
    sendEpdChange(val) {
      if (val) {
        if (/.+@.+\..+/.test(this.login)) {
          this.emailEpd = this.login;
        }
      } else if (this.sendEpdInit) {
        this.accrualsService.sendEpdRequest(this.activeBinding.id, {
          'send_epd': 0,
          'email_epd': null
        });
        this.sendEpdInit = false;
      }
    },
    sendEpdConfirm() {
      this.sendEpdLoading = true;
      try {
        this.accrualsService.sendEpdRequest(this.activeBinding.id, {
          'send_epd': (this.sendEpd ? 1 : 0),
          'email_epd': this.emailEpd
        });
        this.sendEpdInit = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.sendEpdLoading = false;
      }
    },
  },
};
</script>

<style>
/* scoped */
.accruals-table td.v-data-table__mobile-row {
  max-width: calc(100vw - 30px) !important;
}
.accruals-table .v-data-table__mobile-row__header {
  text-align: left;
}
.email-input {
  max-width: 280px;
}
</style>
